<template lang="">
    <!-- Ec checkout page -->
    <section class="sticky-header-next-sec ec-page-content section-space-p">
        <div class="col-12 text-center" v-if="cart && cart.cart_items.length < 1">
            <h4>No Items here</h4>
            <router-link to="/">Back to home</router-link>
        </div>
        <div class="container" v-if="cart && cart.cart_items.length > 0">
            <div class="row">
                <div class="ec-checkout-leftside col-lg-8 col-md-12 ">
                    <p><span class="text-light bg-danger rounded-circle p-2 step-checkout">1</span> <span class="fs-5">{{$t("First Step")}}</span></p>
                    <!-- <hr class="text-dark"> -->
                    <!-- checkout content Start -->
                    <div class="ec-checkout-content mb-4">
                        <div class="ec-checkout-inner">
                            <div class="ec-checkout-wrap margin-bottom-30 padding-bottom-3">
                                <div class="ec-checkout-block ec-check-bill">
                                    <h4 class="ec-checkout-title">{{$t("Billing Details")}}</h4>
                                    <div class="ec-bl-block-content">
                                        <div class="ec-check-subtitle">{{$t("Checkout Options")}}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="addresses">
                                <div 
                                    v-for="(address,index) in addresses"
                                    class="d-flex justify-content-between bg-light p-2 mb-2"
                                >
                                <div>
                                    <input 
                                    type="radio" 
                                    :value="address['id']" 
                                    class="w-auto h-auto me-2"
                                    v-model="AddressID"
                                    @change="ApplyTax()"
                                    name="user_address_id"
                                    :id="'address'+address['id']"
                                    >
                                    <label class="mb-0" :for="'address'+address['id']">
                                        {{address['name']}},
                                        {{address['address_1']}},
                                        {{address['country']}},
                                        {{address['email']}},
                                        {{address['phone']}}
                                    </label>
                                </div>
                                <!-- <div>
                                    <span class="d-flex justify-content-evenly">
                                        <a href="javascript:void(0)" class="me-1" data-bs-toggle="modal" @click="prepareAddress('edit', address)" data-bs-target="#address"><EditOutlined class="fs-6"/></a>
                                        <a href="javascript:void(0)" @click="deleteAddress(address)"><DeleteOutlined class="text-danger fs-6"/></a>
                                    </span>
                                </div> -->
                                </div>
                                <div v-if="addresses.length == 0" class="mb-2">No addresses found, add new one</div>
                                <a class="btn btn-lg btn-primary" @click="prepareAddress('add')" data-bs-toggle="modal" data-bs-target="#address" href="javascript:void(0)">{{$t("Add Address")}}</a>
                            </div>
                        </div>
                    </div>
                    <!--cart content End -->
                    <div class="ec-sidebar-wrap ec-checkout-pay-wrap border-0 p-0 mb-4">
                        <!-- Sidebar Payment Block -->
                        <p><span class="text-light bg-warning rounded-circle p-2 step-checkout">2</span> <span class="fs-5">{{$t("Second Step")}}</span></p>
                        <div class="ec-sidebar-block">
                            <div class="ec-sb-title">
                                <h3 class="ec-sidebar-title">{{$t("Payment Method")}}</h3>
                            </div>
                            <div class="ec-sb-block-content">
                                <div class="ec-checkout-pay">
                                    <div class="ec-pay-desc">{{$t("Please select the preferred payment method to use on this order.")}}</div>
                                    <form action="#">
                                        <span class="ec-pay-option">
                                            <span class="d-block">
                                                <input type="radio" v-model="payment_method" value="cash_on_delivery" class="h-auto w-auto me-2" id="pay1" name="radio-group" checked>
                                                <label for="pay1">{{$t("Cash On Delivery")}}</label>
                                            </span>
                                            <span class="d-block" v-if="totalAmount != 0">
                                                <input type="radio" v-model="payment_method" value="paymob" class="h-auto w-auto me-2" id="pay2" name="radio-group">
                                                <label for="pay2">{{$t("Paymob")}}</label>
                                            </span>
                                        </span>
                                        <span class="ec-pay-commemt">
                                            <span class="ec-pay-opt-head">{{$t("Add Comments About Your Order")}}</span>
                                            <textarea name="your-commemt" v-model="description"></textarea>
                                        </span>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- Sidebar Payment Block -->
                    </div>
                    <span class="ec-check-order-btn flex-column align-items-end" v-if="addresses.length > 0"> 
                        <p><span class="text-light bg-success rounded-circle p-2 step-checkout">3</span> <span class="fs-5">{{$t("Third Step")}}</span></p>
                        <button class="btn btn-primary" @click="CreateOrder()" :disabled="AddressID == null || isLoading('OrderCreate')">
                            {{payment_method == "paymob" ? $t("Add Cart") : $t("Place Order")}}
                            <loading-outlined class="fs-6 ms-2" v-if="isLoading('OrderCreate')"/>
                        </button>
                    </span>
                </div>
                <!-- Sidebar Area Start -->
                <div class="ec-checkout-rightside col-lg-4 col-md-12">
                    <div class="ec-sidebar-wrap">
                        <!-- Sidebar Summary Block -->
                        <div class="ec-sidebar-block position-relative">
                            <div class="ec-sb-title mb-2">
                                <h3 class="ec-sidebar-title">{{$t("Summary")}}</h3>
                            </div>
                            <div class="ec-sb-block-content">
                                <transition name="fade" mode="out-in">
                                    <div v-if="isLoading('ApplyCoupon') || isLoading('ApplyTax')" class="loader position-absolute top-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                        <loading-outlined class="fs-3"/>
                                    </div>
                                </transition>
                                <div class="ec-checkout-summary">
                                    <div>
                                        <span class="text-left">{{$t("Sub Total")}}</span>
                                        <span class="text-right">{{subtotal}} {{currency}}</span>
                                    </div>
                                    <div>
                                        <span class="text-left">{{$t("Total Discount")}}</span>
                                        <span class="text-right">{{totalDiscount}} {{currency}}</span>
                                    </div>
                                    <div>
                                        <span class="text-left">{{$t("Shipping Value")}}</span>
                                        <span class="text-right">{{countryTax}} {{currency}}</span>
                                    </div>
                                    <div>
                                        <span class="text-left">{{$t("Shipping fee")}}</span> 
                                        <span class="text-right">{{shippingFee}} {{currency}}</span>
                                    </div>
                                    <div>
                                        <span class="text-left">{{$t("Coupon Discount")}}</span>
                                    </div>
                                    <transition name="fade" mode="out-in">
                                        <div class="ec-checkout-coupan-content d-block" v-if="coupon_flag">
                                        <form class="ec-checkout-coupan-form" name="ec-checkout-coupan-form" @submit.prevent="ApplyCoupon">
                                            <input class="ec-coupan" type="text" v-model="Coupon" required="" :placeholder="$t('Enter Your Coupan Code')" name="ec-coupan">
                                            <button class="ec-coupan-btn button btn-primary d-flex align-items-center" type="submit" name="subscribe" :disabled="isLoading('ApplyCoupon')">
                                                {{$t("Apply")}}
                                                <loading-outlined class="ms-3" v-if="isLoading('ApplyCoupon')"/>
                                            </button>
                                        </form>
                                    </div>
                                    </transition>
                                    <div v-if="couponDetails">
                                        <div>
                                            {{couponDetails['code']}} 
                                            <b class="text-success">{{couponDetails['discount_amount']}} {{currency}}</b>
                                        </div>
                                        <a href="javascript:void(0)" @click="deleteCoupon()"><DeleteOutlined class="text-danger fs-6"/></a>
                                    </div>
                                    <div class="ec-checkout-summary-total">
                                        <span class="text-left">{{$t("Total")}}</span>
                                        <span class="text-right">{{totalAmount}} {{currency}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Sidebar Summary Block -->
                    </div>
                    <div class="ec-sidebar-wrap">
                        <div class="ec-sb-title mb-2">
                            <h3 class="ec-sidebar-title">{{$t("My Cart")}}</h3>
                        </div>
                        <!-- Sidebar Summary Block -->
                        <div class="ec-cart-top">
                        <ul class="eccart-pro-items" v-if="cart">
                            <li v-for="item in cart.cart_items" v-key="item.id" class="position-relative">
                                <transition name="fade" mode="out-in">
                                <div v-if="isLoading('Remove_Product_From_Cart'+item.id) || isLoading('Product_Increase_Decrease_From_Cart'+item.id)" class="loader position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                                    <loading-outlined class="fs-3"/>
                                </div>
                                </transition>
                                <span @click="fetchProductData(item.product_id)" class="sidekka_pro_img_parent">
                                    <router-link :to="'/product/'+item['product_id']" class="sidekka_pro_img">
                                        <img :src="item.attribute != undefined && item.attribute.length != 0 ? route + item.attribute.image: route + item['image']" alt="product">
                                    </router-link>
                                </span>

                                <div class="ec-pro-content">
                                    <span @click="fetchProductData(item.product_id)"><router-link :to="'/product/'+item['product_id']" class="cart_pro_title">{{item['name']}}</router-link></span>
                                    <span class="cart-price"><span>{{currency}} {{item['price']}}</span> x {{item['quantity']}}</span>
                                    <div v-if="item.attribute">
                                        <p class="mb-0">{{item.attribute.color != null ? ($i18n.locale == 'en' ? item.attribute.color.name_en : item.attribute.color.name_ar) : ""}}</p>
                                        <p class="mb-0">{{item.attribute.size != null ? ($i18n.locale == 'en' ? item.attribute.size.name_en : item.attribute.size.name_ar) : ""}}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                        <!-- Sidebar Summary Block -->
                    </div>
                </div>
            </div>
        </div>
        <add-address :addressData="currentAddress" :mode="addressMode"/>
    </section>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import {EyeOutlined, DeleteOutlined, EditOutlined, LoadingOutlined} from '@ant-design/icons-vue';
import { router } from "@/router";
export default {
    data(){
        return{
            addressMode: 'add',
            coupanFlag:false,
            description:"",
            payment_method:"cash_on_delivery",
            Coupon:null,
            AddressID:null,
            currentAddress: {},
        }
    },
    components: {
        EyeOutlined,
        DeleteOutlined,
        EditOutlined,
        LoadingOutlined
    },
    computed: {
        ...mapState(['addresses','couponDetails','coupon_flag','coupon_flag_2','cart','currency', 'route']),
        ...mapGetters(['subtotal','totalDiscount','couponDiscount','totalAmount','countryTax','shippingFee']),
        isAuthenticated() {
            window.scrollTo(0,0)
            return this.$store.state.isAuthenticated;
        },
    },
    created(){
        if(this.isAuthenticated.user == null){
           router.push("/");
        }
    },
    methods: {
        ...mapActions(['GetUserAddresses','GetCartData']),
        async fetchUserAddresses() {
            await this.GetUserAddresses();
        },
        async fetchCart() {
            await this.GetCartData();
        },
        prepareAddress(mode, address = {}) {
            this.addressMode = mode;
            this.currentAddress = { ...address };
        },
        fetchProductData(id){
            $(window).scrollTop(0); 
            this.$store.dispatch('GetProductData',{id: id})
        },
        ApplyCoupon(){
            this.$store.dispatch("ApplyCoupon",this.Coupon)
        },
        deleteAddress(address){
            this.$store.dispatch("DeleteAddress",address)
            if(address.id == this.AddressID){
                this.AddressID = null
            }
        },
        ApplyTax(){
            let coupon;
            if(this.couponDetails != null){
                coupon = this.couponDetails['code']
            }
            this.$store.dispatch("ApplyTax",{coupon:coupon, address_id:this.AddressID})
        },
        deleteCoupon(){
            this.$store.commit('APPLY_COUPON', null);
            this.$store.commit('COUPON_FLAG', true);
        },
        toggleCoupon(){
            this.$store.commit('COUPON_FLAG_2');
        },
        isLoading(actionName) {
            return this.$store.state.Loading[actionName] || false;
        },
        CreateOrder(){
            let coupon;
            if(this.couponDetails != null){
                coupon = this.couponDetails['code']
            }
            this.$store.dispatch("OrderCreate",{
                coupon:coupon, 
                user_address_id:this.AddressID, 
                payment_method:this.payment_method,
                description: this.description
            })
        }
    },
    mounted() {
        this.fetchUserAddresses();
        this.fetchCart();
        this.AddressID = null
    },
}
</script>
<style lang="">
    
</style>