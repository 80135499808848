<template lang="">
<div class="col-md-12 tab-pane fade" id="tab-cat-orders" v-if="orders">
    <div class="ec-vendor-card-header p-0 pb-4">
        <h5>{{$t("Orders")}}</h5>
    </div>
    <div class="">
        <div class="ec-vendor-card-table">
            <table class="table ec-table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{$t("Status")}}</th>
                        <th scope="col">{{$t("Payment Status")}}</th>
                        <th scope="col">{{$t("Payment Method")}}</th>
                        <th scope="col">{{$t("Currency")}}</th>
                        <th scope="col">{{$t("Total")}}</th>
                        <th scope="col">{{$t("Action")}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(order, index) in orders"
                        class="position-relative"
                    >
                        <th scope="row">
                            <span>{{index+1}}</span>
                        </th>
                        <td>
                            <span>{{order['status']}}</span>
                            <transition name="fade" mode="out-in">
                                <div v-if="isLoading('OrderCancel'+order.id)" class="loader position-absolute top-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                    <loading-outlined class="fs-3"/>
                                </div>
                            </transition>
                        </td>
                        <td>
                            <span>
                                {{order['payment_status']}}
                            </span>
                        </td>
                        <td><span>{{order['payment_method']}}</span></td>
                        <td><span>{{order['currency']}}</span></td>
                        <td><span>{{order['total']}}</span></td>
                        <td>
                           <span class="d-flex justify-content-evenly align-items-center">
                            <router-link :to="'/order/'+order.id"><EyeOutlined class="fs-6"/></router-link>
                            <a-tooltip  placement="bottom">
                            <template #title>{{$t("Cancel Order")}}</template>
                            <button v-if="order['status'] !== 'cancelled' && order['status'] !== 'refunded' && order['status'] !== 'completed'" class="fs-6 text-danger ec-close" @click="cancelOrder(order.id)">x</button>
                            </a-tooltip>
                           </span>
                        </td>
                    </tr>
                    <tr v-if="orders.length == 0">
                        <td colspan="7" class="text-center"><span>No addresses found</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>
<script>
import {EyeOutlined, DeleteOutlined, EditOutlined, LoadingOutlined} from '@ant-design/icons-vue';
import { mapState, mapActions } from 'vuex'
export default {
    components: {
        EyeOutlined,
        DeleteOutlined,
        EditOutlined,
        LoadingOutlined
    },
    computed: {
        ...mapState(['orders']),
    },
    methods: {
        ...mapActions(['GetUserOrders']),
        async fetchUserOrders() {
            await this.GetUserOrders();
        },
        
        cancelOrder(id){
            this.$store.dispatch("CancelOrder",{order_id:id})
        },
        isLoading(actionName) {
            return this.$store.state.Loading[actionName] || false;
        },
    },
    mounted() {
        this.fetchUserOrders();
    },
}
</script>
<style lang="">
    
</style>