<template lang="">
    <h1>SideBar</h1>
</template>
<script>
export default {
   
}
</script>
<style lang="">
    
</style>