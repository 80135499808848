<template>
  <!-- Modal -->
  <div class="modal fade" id="invitation_model" v-if="this.isAuthenticated.bool" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content col-lg-8 m-auto bg-light" style="background-image: url('@/assets/images/common/invitation.jpg');">
        <img src="@/assets/images/common/invitation.jpg"/>
        <div class="modal-body text-center p-3">
          <h5>{{ $t("You have an invitation for your friends") }}</h5>
          <p class="fw-bold">{{ $t("Your personal invitation code") }}</p>
          <div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-primary h-100 copy-button" @click="copyToClipboard(this.isAuthenticated.user.invitation_code)">{{ $t("Copy") }}</button>
            <strong class="fs-3 px-5 mt-2 rounded cursor-pointer bg-white fw-bold">{{this.isAuthenticated.user.invitation_code}}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <layout-header></layout-header>
  <loader></loader>
  <category-bar></category-bar>
  <mobile-menu></mobile-menu>
  <cart></cart>
  <!-- <settings-side></settings-side> -->
  <router-view/>
  <Toasts/>
  <whatsapp/>
  <layout-footer></layout-footer>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    
  },
  methods:{
    loadCSS(id, path) {
      let existingLink = document.getElementById(id);
      if (!existingLink) {
        let link = document.createElement('link');
        link.id = id;
        link.rel = 'stylesheet';
        link.href = path;
        document.head.appendChild(link);
      }
    },
    copyToClipboard(inviteCode) {
      const textToCopy = inviteCode; // Replace with the text you want to copy

      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          // Copying successful
        })
        .catch((err) => {
        });
    },
    unloadCSS(id) {
      let link = document.getElementById(id);
      if (link) {
        document.head.removeChild(link);
      }
    }
  },
  computed: {
      isAuthenticated() {
          return this.$store.state.isAuthenticated;
      },
  },
  watch:{
    '$i18n.locale': function (newVal) {
      
     const cssPath = 'https://jupiter-eg.com/rtl/rtl.css'; // Adjust the path based on your project structure
      if (newVal === 'ar') {
        this.loadCSS('rtlStyle', cssPath);
      } else {
        this.unloadCSS('rtlStyle');
      }
    }
  }
}
</script>

<style>

</style>
