<template lang="">
    <!-- Main Slider Start -->
    <div class="sticky-header-next-sec ec-main-slider section section-space-pb">
    <div class="ec-slider swiper-container main-slider-nav main-slider-dot p-5">
        <!-- Main slider -->
        <swiper
            :slides-per-view="1"
            :space-between="50"
            :navigation="true"
            :modules="modules"
            @swiper="onSwiper"
            :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
            }"
            @slideChange="onSlideChange"
        >
            <swiper-slide 
                v-lazy-container="{ selector: 'img' }"
                v-for="advertisement in home_products.advertisements"
                :key="advertisement.id"
                class="ec-slide-item swiper-slide d-flex ec-slide-1"
                :style="{ backgroundImage: 'url('+ route + advertisement.image + ')' }"
            >
            <a 
            :href="advertisement.banner_url"
            class="w-100 h-100 position-relative"
            >
                <div class="position-absolute advertisement-shadow">
                    <h1 class="ec-offer-title text-light fs-4">{{advertisement['name']}}</h1>
                    <h3 class="ec-offer-stitle slideInDown text-light fs-5" data-animation="slideInDown" data-animated="true">Check this out now!</h3>
                </div>
                <div class="container align-self-center">
                    <div class="row">
                        <div class="col-xl-6 col-lg-7 col-md-7 col-sm-7 align-self-center">
                            <div class="ec-slide-content slider-animation"></div>
                        </div>
                    </div>
                </div>
            </a>
            </swiper-slide>
        </swiper>
    </div>
</div>
<!-- Main Slider End -->
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { mapActions, mapState } from "vuex";
import 'swiper/css';
import { Autoplay, Navigation } from 'swiper/modules';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapState(['home_products','route']),
    },
    setup() {
        return {
            modules: [Autoplay, Navigation],
        };
    },
}
</script>
<style lang="">

</style>