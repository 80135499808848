<template lang="">
	<section class="ec-404-error-page-02">
		<div class="container">
		  <div class="row">
			<div class="col-sm-12 text-center">
				<img class="img-404" src="@/assets/images/common/404-error-page.gif" alt="" />

				<h1 class="main-title text-center">404</h1>
				<h3 class="sub-title text-center">Look like you're lost</h3>
	
				<p class="desc-content text-center">the page you are looking for not avaible!</p>
	
				<router-link class="link-404" to="/">Go to Back</router-link>
			</div>
		  </div>
		</div>
	</section>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>