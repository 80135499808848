<template lang="">
    <!-- Whatsapp -->
    <div class="ec-style ec-right-bottom">
        <div class="ec-right-bottom">
            <div class="ec-box">
                <a href="https://wa.me/+201069000501" class="ec-button rotateBackward">
                    <img class="whatsapp" src="@/assets/images/common/whatsapp.png" alt="whatsapp icon">
                </a>
            </div>
        </div>
        <!--/ End Right Floating Button-->
    </div>
    <!-- Whatsapp end -->
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>