<template lang="">
    <div class="ec-overlay"><span class="loader_img"></span></div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>