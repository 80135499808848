import {createRouter, createWebHistory} from 'vue-router';
import Login from '@/views/pages/Auth/login'
import Home from '@/views/pages/Home/index'
import Products from '@/views/pages/Products/Products'
import OrderDetails from '@/views/pages/Order/order-details'
import CheckOut from '@/views/pages/Order/checkout'
import Cart from '@/views/pages/Cart/cart'
import Wishlist from '@/views/pages/wishlist/wishlist'
import Product from '@/views/pages/Product/product'
import SidePage from '@/views/pages/SidePages/side-pages'
import Games from '@/views/pages/Games/games'
import Error from '@/views/pages/404/404'
import Contact from '@/views/pages/Contact/contact'
import Profile from '@/views/pages/Profile/profile'
import ThankYou from '@/views/pages/Order/thank-you'
import OrderFailed from '@/views/pages/Order/order-failed'
import Register from '@/views/pages/Auth/register'
import Verification from '@/views/pages/Auth/verification'
import ForgetPassword from '@/views/pages/Auth/forget_password'
import ResetPassword from '@/views/pages/Auth/reset_password'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta:{
      title: "Jupiter EG - Home"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta:{
      title: "Jupiter EG - Login"
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: Error,
    meta:{
      title: "Jupiter EG - 404"
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta:{
      title: "Jupiter EG - Register"
    }
  },
  {
    path: '/order-success',
    name: 'order-success',
    component: ThankYou,
    meta:{
      title: "Jupiter EG - Order Success"
    }
  },
  {
    path: '/order-failed',
    name: 'order-failed',
    component: OrderFailed,
    meta:{
      title: "Jupiter EG - Order Failed"
    }
  },
  {
    path: '/verification',
    name: 'verification',
    component: Verification,
    meta:{
      title: "Jupiter EG - Verification"
    }
  },
  {
    path: '/forget_password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta:{
      title: "Jupiter EG - Forget Password"
    }
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta:{
      title: "Jupiter EG - Reset Password"
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta:{
      title: "Jupiter EG - Cart"
    }
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: Wishlist,
    meta:{
      title: "Jupiter EG - Wishlist"
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta:{
      title: "Jupiter EG - Profile"
    }
  },
  {
    path: '/games',
    name: 'games',
    component: Games,
    meta:{
      title: "Jupiter EG - Wheelspin"
    }
  },
  {
    path: "/product/:id",
    name: "product",
    component: Product,
    meta:{
      title: "Jupiter EG - Product"
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta:{
      title: "Jupiter EG - Contact"
    }
  },
  {
    path: "/order/:id",
    name: "order",
    component: OrderDetails,
    meta:{
      title: "Jupiter EG - Order"
    }
  },
  {
    path: "/checkout",
    name: "checkout",
    component: CheckOut,
    meta:{
      title: "Jupiter EG - Checkout"
    }
  },
  {
    path: "/terms",
    name: "Terms & Conditions",
    component: SidePage,
    props: route => ({ apiEndpoint: "terms" }),
    meta:{
      title: "Jupiter EG - Order Failed"
    }
  },
  {
    path: "/about",
    name: "About Us",
    component: SidePage,
    props: route => ({ apiEndpoint: "about" }),
    meta:{
      title: "Jupiter EG - Order Failed"
    }
  },
  {
    path: "/privacy",
    name: "Privacy and policy",
    component: SidePage,
    props: route => ({ apiEndpoint: "privacy" }),
    meta:{
      title: "Jupiter EG - Privacy and policy"
    }
  },
  {
    path: "/return-policy",
    name: "Return Policy",
    component: SidePage,
    props: route => ({ apiEndpoint: "return-policy" }),
    meta:{
      title: "Jupiter EG - Return Policy"
    }
  },
  {
    path: "/store-policy",
    name: "Store Policy",
    component: SidePage,
    props: route => ({ apiEndpoint: "store-policy" }),
    meta:{
      title: "Jupiter EG - Store Policy"
    }
  },
  {
    path: "/seller-policy",
    name: "Seller Policy",
    component: SidePage,
    props: route => ({ apiEndpoint: "seller-policy" }),
    meta:{
      title: "Jupiter EG - Seller Policy"
    }
  },
  {
    path: "/last",
    name: "Last Products",
    component: Products,
    props: route => ({ apiEndpoint: "products/last" }),
    meta:{
      title: "Jupiter EG - Last Products"
    }
  },
  {
    path: "/hotItems",
    name: "Hot Items",
    component: Products,
    props: route => ({ apiEndpoint: "products/hotitem" }),
    meta:{
      title: "Jupiter EG - Hot Items"
    }
  },
  {
    path: "/search-product/:keyword",
    name: "Search",
    component: Products,
    props: route => ({ apiEndpoint: "search-product" }),
    meta:{
      title: "Jupiter EG - Search"
    }
  },
  {
    path: "/toprate",
    name: "Top rate products",
    component: Products,
    props: route => ({ apiEndpoint: "products/toprate" }),
    meta:{
      title: "Jupiter EG - Top rated"
    }
  },
  {
    path: "/with-offers",
    name: "Products Offers",
    component: Products,
    props: route => ({ apiEndpoint: "products/with-offers" }),
    meta:{
      title: "Jupiter EG - Products Offers"
    }
  },
  {
    path: "/subCategory/:id",
    name: "Sub category",
    component: Products,
    props: route => ({ apiEndpoint: `subcategories/${route.params.id}/products` }),
    meta:{
      title: "Jupiter EG - Sub category"
    }
  }
];
export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) =>{
  document.title = `${to.meta.title}`;
  next()
})